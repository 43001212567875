import React, { useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const DashboardCharts = ({ generalInformation, reviewAnalysis }) => {
  const generalInfo = generalInformation || [];
  const reviews = reviewAnalysis || [];

  useEffect(() => {
    console.log("General Information:", generalInfo);
    console.log("Review Analysis (Raw Reviews):", reviews);
  }, [generalInfo, reviews]);

  const calculateAverageRatingPerCompany = () => {
    const ratingSum = {};
    const ratingCount = {};

    reviews.forEach(review => {
      const company = review.company;
      const rating = parseFloat(review.rating);
      if (!isNaN(rating)) {
        ratingSum[company] = (ratingSum[company] || 0) + rating;
        ratingCount[company] = (ratingCount[company] || 0) + 1;
      }
    });

    const averageRatings = {};
    Object.keys(ratingSum).forEach(company => {
      averageRatings[company] = (ratingSum[company] / ratingCount[company]).toFixed(2);
    });

    console.log("Average Ratings Per Company:", averageRatings);
    return averageRatings;
  };

  const calculateReviewsPerCompany = () => {
    const reviewCount = {};

    reviews.forEach(review => {
      const company = review.company;
      reviewCount[company] = (reviewCount[company] || 0) + 1;
    });

    console.log("Review Count Per Company:", reviewCount);
    return reviewCount;
  };

  const getBarData = (labels, values, label) => {
    return {
      labels,
      datasets: [{
        label: label,
        data: values,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };
  };

  const getPieData = (labels, values) => {
    return {
      labels,
      datasets: [{
        data: values,
        backgroundColor: labels.map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`)
      }]
    };
  };

  const averageRatings = calculateAverageRatingPerCompany();
  const companies = Object.keys(averageRatings);
  const averageValues = companies.map(company => averageRatings[company]);

  const reviewCount = calculateReviewsPerCompany();
  const reviewValues = companies.map(company => reviewCount[company] || 0);
  const getGridTemplateColumns = (numItems) => {
  if (numItems <= 2) {
    return 'repeat(2, 1fr)';  // Use 2 columns if there are 2 or fewer items
  }
  return 'repeat(3, 1fr)';    // Default to 3 columns for 3 or more items
};


  return (
 <div style={{
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',  // First row has 3 columns
  gap: '20px',
  padding: '0 40px',
  justifyItems: 'center',
  alignItems: 'start'
}}>
  {/* First row - 3 graphs */}
  <div>
    <h2>Average Rating per Company</h2>
    {companies.length > 0 ? (
      <Bar data={getBarData(companies, averageValues, 'Average Rating')} />
    ) : (
      <p>No review data available.</p>
    )}
  </div>

  <div>
    <h2>Annual Recurring Revenue</h2>
    {generalInfo.length > 0 ? (
      <Pie data={getPieData(generalInfo.map(item => item.company), generalInfo.map(item => item.annual_recurring_revenue))} />
    ) : (
      <p>No revenue data available.</p>
    )}
  </div>

  <div>
    <h2>Number of Reviews per Company</h2>
    {companies.length > 0 ? (
      <Bar data={getBarData(companies, reviewValues, 'Number of Reviews')} />
    ) : (
      <p>No review data available.</p>
    )}
  </div>

  {/* Second row - 2 graphs, spaced evenly */}
  <div style={{
    gridColumn: 'span 2',  // This ensures both graphs are spaced equally on the left and right
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '300px',
    width: '100%'  // Ensures the row is full-width for equal spacing
  }}>
    <div>
      <h2>Number of Employees per Company</h2>
      {generalInfo.length > 0 ? (
        <Bar data={getBarData(generalInfo.map(item => item.company), generalInfo.map(item => item.number_of_employees), 'Number of Employees')} />
      ) : (
        <p>No employee data available.</p>
      )}
    </div>

    <div>
      <h2>Number of LinkedIn Followers per Company</h2>
      {generalInfo.length > 0 ? (
        <Bar data={getBarData(generalInfo.map(item => item.company), generalInfo.map(item => item.number_of_linkedin_followers), 'Number of LinkedIn Followers')} />
      ) : (
        <p>No LinkedIn follower data available.</p>
      )}
    </div>
  </div>
</div>
);
};

export default DashboardCharts;